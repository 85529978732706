import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { TwitchEmbed } from "react-twitch-embed";
import axios from 'axios';

import { PageLayout } from "../layouts";
import { GetStartedBlock, Block, PartnersBlock, TwitchStreams, TrackedLink } from '../components';

import { TWITCH_STREAMERS } from "../global/js/helpers.js";

import SmokeLeft from "../images/smoke-left.svg";
import SmokeRight from "../images/smoke-right.svg";
import SoldiersLeft from "../images/soldiers-left.svg";
import SoldiersRight from "../images/soldiers-right.svg";
import JoinTheLeadersBox from "../images/join-the-leader-box.svg";

import "../global/styles/page-styles/leaders-page.scss";

const API_ENDPOINT_USER = process.env.API_ENDPOINT_TWITCH_USER;

let displayedStream = "partie";
let active = false;
let dragging = false;

const LeadersPage = () => {
  const [embed, setEmbed] = useState([]);

  /**
   * Handle switching of twitch streams
   * Removes active overlay and makes new active overlay
   */
  const handleClick = e => {
    if (dragging) e.stopPropagation()
    if (!dragging) {
      for (let i = 0; i < document.getElementsByClassName("stream").length; i++) {
        document.getElementsByClassName("stream")[i].className = "stream";
      }
      if (e.target.innerHTML.length > 3) {
        displayedStream = e.target.innerHTML;
        setEmbed(
          <TwitchEmbed
            channel={displayedStream}
            className={displayedStream}
            id="twitch-channel"
            theme="dark"
            muted
            width="100%"
            height="100%"
            parent={["partie.com"]}
          />
        );
      }
      document.getElementById(displayedStream).className = "stream active";
    }
  }

  const handleBeforeChange = () => {
    dragging = true;
  }

  const handleAfterChange = () => {
    dragging = false;
  }

  /**
   * Chooses which stream will be streaming
   */
  const handleDisplayedStream = () => {
    axios.get(`${API_ENDPOINT_USER}?channelName=${displayedStream}`)
      .then((res) => {
        if (res.data.is_live == false && active == true && res.data.display_name.toLowerCase() == displayedStream.toLowerCase()) {
          active = false;
        }
        if (res.data.is_live == true && active == false) {
          return;
        }
      })
      .catch(error => {
        console.log('Fetch error :: ', error);
      })
    TWITCH_STREAMERS.forEach(channel => {
      axios.get(`${API_ENDPOINT_USER}?channelName=${channel}`)
        .then((res) => {
          if (res.data.is_live == false && active == true && res.data.display_name.toLowerCase() == displayedStream.toLowerCase()) {
            active = false;
          }
          if (res.data.is_live == true && active == false) {
            displayedStream = res.data.display_name;
            setEmbed(
              <TwitchEmbed
                channel={displayedStream}
                className={displayedStream}
                id="twitch-channel"
                theme="dark"
                muted
                width="100%"
                height="100%"
                parent={["partie.com"]}
              />
            );
            active = true;
          }
        })
        .catch(error => {
          console.log('Fetch error :: ', error);
        })
    })
  }

  /**
   * Checks and changes the active stream & thumbnails
   * Current interval is set for 30 sec
   */
  useEffect(() => {
    active = false;
    handleDisplayedStream();
    const interval = setInterval(() => {
      handleDisplayedStream();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Helmet>
        <title>Partie | Leaders</title>
        <html lang="en" />
        <meta name="description" content="Partie Leaders is a division of our company dedicated to supporting positive and engaging players, creators and esports talent, by providing revenue opportunities, consulting, management and creative services." />
      </Helmet>
      <PageLayout className="leaders-page">
        <Block centered hero lazy>
          <h2 className="partie-block__title">Partie Leaders</h2>
          <p className="partie-block__description">
            Partie Leaders is a division of our company <br className="hidden" />
            dedicated to supporting positive and engaging players,<br className="hidden" />
            creators and esports talent, by providing revenue opportunities,<br className="hidden" />
            consulting, management and creative services.
          </p>
          <div className="main-page-hero-background" aria-hidden>
            <img src={SmokeLeft} className="smoke-left" alt="" aria-hidden />
            <img src={SmokeRight} className="smoke-right" alt="" aria-hidden />
            <img src={SoldiersLeft} className="soldiers-left" alt="" aria-hidden />
            <img src={SoldiersRight} className="soldiers-right" alt="" aria-hidden />
          </div>
        </Block>
        <Block className="twitch-block" centered lazy>
          <h2 className="partie-block__title">Stay Updated</h2>
          <div id="twitch">
            <div id="twitch-embed">
              {embed}
            </div>
            <TwitchStreams handleBeforeChange={handleBeforeChange} handleAfterChange={handleAfterChange} handleClick={handleClick} />
          </div>
          <div className="partie-block__cta">
            <TrackedLink type="btn btn--accent" to="/leaders/apply" children="Meet the Leaders" />
          </div>
        </Block>
        <PartnersBlock />
        <Block className="share-block" stacked lazy>
          <div className="partie-block__column">
            <img
              className="leaders-page__hero-img"
              loading="lazy"
              src={JoinTheLeadersBox}
              alt="Join the Leader Box"
            />
          </div>
          <div className="partie-block__column">
            <h3 className="partie-block__title">Join the Leaders</h3>
            <p className="partie-block__description">
              We're actively recruiting exceptional individuals <br className="hidden" />
              dedicated to promoting positive values in gaming.<br />
              <br />
              Sound like you?
            </p>
            <div className="partie-block__cta">
              <TrackedLink type="btn btn--accent" to="/leaders/apply" children="Apply Here" />
            </div>
          </div>
        </Block>
        <GetStartedBlock />
      </PageLayout>
    </>
  );
};

export default LeadersPage;